import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'QuemSomos',
  components: {
    VueSlickCarousel,
    Pilares: () => import('src/components/Pilares/Pilares.vue'),
    Vantagens: () => import('src/components/Vantagens/Vantagens.vue'),
    EntendaAgora: () => import('src/components/EntendaAgora/EntendaAgora.vue'),
    CategoriaCarrossel: () => import('src/components/CategoriaCarrossel/CategoriaCarrossel.vue'),
    ConfiraTodos: () => import('src/components/ConfiraTodos/ConfiraTodos.vue')
  },
  meta () {
    return {
      title: 'Saiba quem somos e como funciona - NetCarros',
      description: { name: 'description', content: 'Na NetCarros é muito fácil sair de carro novo, são 4 etapas e você faz tudo de casa. Faça uma pré analise de crédito. Quer encontrar seu carro? Acesse já!' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-como-funciona.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'Saiba quem somos e como funciona - NetCarros' },
        { 'name': 'description', 'content': 'Na NetCarros é muito fácil sair de carro novo, são 4 etapas e você faz tudo de casa. Faça uma pré analise de crédito. Quer encontrar seu carro? Acesse já!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-como-funciona.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-como-funciona.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  monted () {
    this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20gostaria%20de%20saber%20com%20quais%20carros%20vcs%20trabalham?'
    document.querySelector('.botoes a').href = this.linkpage
  },
  methods: {
    scroll () {
      if (this.$refs.quemSomos) {
        this.$refs.quemSomos.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }
}
